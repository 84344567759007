.main {
  display: flex;
  flex-direction: column;
  background-image: url("./images/AdobeStock_286233742.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100vw;
  height: 100vh;
  background-attachment: fixed;
  overflow: hidden;

}

.h1_KreisOverlay{
  display: none;
}

.hauptueberschrift {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%; 
}

.hauptueberschrift h1 {
  color: #80358c;
  font-size: 6vw;
  text-shadow: 0.5vw 0.5vw 0.7vw rgba(55, 32, 100, 0.6);
}

.hauptueberschrift h1 .word {
  display: inline-block;
}

.logo {
  width: 30vw;
  max-width: 60vw;
  margin-top: 2%;
  margin-left: 2%;
}



@media only screen and (max-width: 2000px) {
  .logo {
    width: 15vw;
  }

  .hauptueberschrift h1 {
    font-size: 3vw;
  }
}

@media only screen and (min-width: 1026px) and (max-width: 1366px) {
  .logo {
    width: 30vw;
    margin-left: 5%;
    margin-top: 5%;
  }

  .hauptueberschrift h1 {
    font-size: 6vw;
  }
}



@media only screen and (min-width: 768px) and (max-width:1025px){
  .main {
    background-position: center 0vh;
    background-size: 100% 50vh;
    background-attachment: unset;
    overflow: hidden;
    background-color: #e7e0eb;
    width: 100vw;
  }

  .logo {
    width: 25vw;
  }

  .hauptueberschrift h1 {
    display: none;
  }
  .kreisOverlay {
    position: absolute;
    top: 55%;
    right: 0;
    transform: translateY(-50%);
    background-color: #aa4eb8;
    width: 50vw;
    height: 50vh; 
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    padding: 20px;
    text-align: center;
    opacity: 0.2;
  }
  .h1_KreisOverlay {
    width: 80%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 4vw;
    font-weight: bold;
    text-transform: uppercase; 
    letter-spacing: 2px; 
    padding: 20px;
    margin-bottom: 10vh;
    margin-right: auto;
    margin-left: auto;
    line-height: 10vh;
    color: #80358c;
  }
  

  .underline {
    width: 80%;
    margin: 0 10%; 
    border-bottom: 4px solid black; 
    margin-bottom: 15vh;
  }
  
  
  
}




@media only screen and (min-width: 768px) and (max-width:800px)and (orientation: landscape) {
  .main {
    background-position: center 0vh;
    background-size: 100% 70vh;
    background-attachment: unset;
    overflow: hidden;
    background-color: #e7e0eb;
    width: 100vw;
    height: 100%;
  }

  .logo {
    margin:20px;
    width: 20vw;
  }

  .hauptueberschrift h1 {
    display: none;
  }
  .kreisOverlay {
    position: absolute;
    top: 85%;
    right: 0; 
    transform: translateY(-50%);
    background-color: #aa4eb8;
    width: 50vw;
    height: 50vh; 
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    padding: 20px;
    text-align: center;
    opacity: 0.2;
  }
  .h1_KreisOverlay {
    width: 80%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 4vw;
    font-weight: bold;
    text-transform: uppercase; 
    letter-spacing: 2px; 
    padding: 20px;
    margin-top: 25vh;
    margin-right: auto;
    margin-left: auto;
    line-height: 10vw;
    color: #80358c;
  }
  

  .underline {
    width: 80%;
    margin: 0 10%; 
    border-bottom: 4px solid black; 
    margin-bottom: 15vh;
  }
  
  
  
}


@media only screen and (max-width: 767px) {
  .main {
    background-position: center 0vh;
    background-size: 100% 40vh;
    background-attachment: unset;
    overflow: hidden;
    background-color: #e7e0eb;
    width: 100vw;
  }

  .logo {
    margin:20px;
    width: 30vw;
  }

  .hauptueberschrift h1 {
    display: none;
  }
  .kreisOverlay {
    position: absolute;
    top: 55%;
    right: 0; 
    transform: translateY(-50%);
    background-color: #aa4eb8;
    width: 50vw;
    height: 50vh; 
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    padding: 20px;
    text-align: center;
    opacity: 0.2;
  }
  .h1_KreisOverlay {
    width: 80%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 6vw;
    font-weight: bold;
    text-transform: uppercase; 
    letter-spacing: 2px; 
    padding: 20px;
    margin-bottom: 10vh;
    margin-right: auto;
    margin-left: auto;
    line-height: 10vh;
    color: #80358c;
  }
  

  .underline {
    width: 80%;
    margin: 0 10%; 
    border-bottom: 4px solid black; 
    margin-bottom: 15vh;
  }
  
  
  
}

@keyframes slideFromUp {
  from {
    transform: translateY(10vw);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.AnimationLogoPageOne,
.hauptueberschrift {
  animation-name: slideFromUp;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}
