.fourthPage {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f4f4f4;
  width: 100vw;
}

.videoContainer {
  position: relative;
  width: 90%;
  height: auto;
  margin: auto;
  margin-top: 10%;
  margin-bottom: 10%;
  border-radius: 30px;
  overflow: hidden;
}

.video {
  width: 100%;
  height: auto;
  border-radius: 30px;
}

.videoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.underline {
  width: 40%;
  margin-bottom: 7vh;
  border-bottom: 3px solid black;
  margin-top: 7vh;
}
