.thirdPage {
  background-color: #e4e4e4;
  width: 100vw;
  height: 100%;
  overflow: hidden;
}


.firstSubContainer,
.secondSubContainer,
.thirdSubContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100vw;
  height: 100%;
}

.firstSubContainer p,
.secondSubContainer p,
.thirdSubContainer p {
  margin-left: 10%;
  margin-right: 2%;
  font-size: 2vw;
}

.firstPicture,
.secondPicture,
.thirdPicture {
  width: 20vw;
  max-width: 100%;
  border-radius: 20px;
  margin-right: 10%;
}

.thirdContainer {
  margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
 
  .firstContainer,
  .SecondContainer,
  .thirdContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 2vw;
  }
  .firstSubContainer,
  .secondSubContainer,
  .thirdSubContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100vw;
    height: 100%;
  }

  .firstSubContainer p,
  .secondSubContainer p,
  .thirdSubContainer p {
    width: 50vw;
    margin-left: 10%;
    font-size: 1em;
    margin-right: 2%;
  }

  .firstPicture,
  .secondPicture,
  .thirdPicture {
    width: 15em;
    max-width: 100%;
    border-radius: 20px;
    margin-right: 10%;
  }
}

@media only screen and (max-width: 599px) {
  .thirdPage {
    background-color: #ebecec;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }

  .firstPicture,
  .secondPicture,
  .thirdPicture {
    display: none;
  }

  .firstSubContainer,
  .secondSubContainer,
  .thirdSubContainer {
    background-position: center center;
    background-repeat: no-repeat;
    width: 80vw;
    height: 100%;
    background-size: contain;
    border-radius: 20px;
  }

  .firstSubContainer p,
  .secondSubContainer p,
  .thirdSubContainer p {
    font-size: medium;
    color: #333;
    width: 90%;
    font-weight: 600;
    line-height: 1.5;
    margin: 0 auto;
    text-align: justify;
  }
}
