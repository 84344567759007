
   
  .applyContainer {
    width: 40vw;
    font-weight: 700;
    background-color: #ffffff;
    border-radius: 15px; 
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4); 
    font-size: 18px;
    line-height: 1.5;
    padding: 2em;
    margin-top: 20vh;
    margin-bottom: 20vh;
    
  }
  
  .applyContainer p {
    color: #333333; 
  }
  
  .applyContainer button {
    background-color: #3498db; 
    color: #ffffff;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, transform 0.3s, color 0.3s;
    margin-top: 1em;
  }
  
  .underline {
  
    width: 40%;
    border-bottom: 3px solid black; 
    margin-top: 7vh;
    margin-bottom: 7vh;
    position: relative;
    left: 30%;
  }
   
  
  
  
  @media only screen and (max-width: 479px) {
    .applyContainer{
      width: 70vw;
      font-size: 15px;
    }
  }
  
  
  .applyContainer button:hover {
    background-color: #2980b9;
    animation: waveAnimation 0.5s ease-in-out infinite;
  }
  
  
  @keyframes waveAnimation {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
  }
  